// Composables
import { createRouter, createWebHistory } from 'vue-router';
import { useStore } from 'vuex';

const routes = [
  {
    path: '/', name: 'Login',
    component: () => import('@/views/Login')
  },
  {
    path: '/feed', name: 'Home',
    component: () => import('@/views/Feed')
  },
  {
    path: '/feed/following', name: 'Following',
    component: () => import('@/views/FeedFollows')
  },
  {
    path: '/sessions', name: 'Sessions',
    component: () => import('@/views/Sessions')
  },
  // {
  //   path: '/JamSession/:sessionId', name: 'JamSession',
  //   component: () => import('@/views/JamSession')
  // },
  {
    path: '/JamSong/:songId', name: 'JamSong',
    component: () => import('@/views/JamSong')
  },
  {
    path: '/profile/:username', name: 'Profile',
    component: () => import('@/views/Profile')
  },
  {
    path: '/groups', name: 'Groups',
    component: () => import('@/views/Groups')
  },
  {
    path: '/groups/:groupId', name: 'ViewGroup',
    component: () => import('@/views/Group')
  },
  // {
  //   path: '/set', name: 'Sets',
  //   component: () => import('@/views/Sets')
  // },
  {
    path: '/set/:setId', name: 'Set',
    component: () => import('@/views/Set')
  },
  {
    path: '/admin', name: 'Admin',
    component: () => import('@/views/Admin'),
    beforeEnter: (to, from, next) => {
      const store = useStore();
      const user = store.state.user;

      if (user?.email === process.env.VUE_APP_ADMIN_EMAIL) {
        next();
      } else {
        next({ name: 'Home' });
      }
    },
  },
  {
    path: '/admin/users', name: 'Users',
    component: () => import('@/views/Admin'),
    beforeEnter: (to, from, next) => {
      const store = useStore();
      const user = store.state.user;

      if (user?.email === process.env.VUE_APP_ADMIN_EMAIL) {
        next();
      } else {
        next({ name: 'Home' });
      }
    },
  },
  {
    path: '/terms', name: 'Terms',
    component: () => import('@/views/Terms')
  },
  {
    path: '/settings', name: 'Settings',
    component: () => import('@/views/Settings')
  },
  {
    path: '/logout', name: 'Logout',
    component: () => import('@/views/Logout')
  },
  {
    path: '/:pathMatch(.*)*', name: 'NotFound',
    component: () => import('@/views/NotFound')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
