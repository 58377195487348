<template>
  <router-view />
</template>

<script setup>
import { useHead } from '@vueuse/head';

useHead({
  title: 'Home Page - JamSplit',
  meta: [
    { name: 'description', content: 'JamSplit is a web application that makes it easy for bands and musicians to upload, edit and share recordings of their jam sessions and performances' },
    { property: 'og:title', content: 'Home Page - JamSplit' },
    { property: 'og:site_name', content: 'JamSplit' },
    { property: 'og:description', content: 'JamSplit is a web application that makes it easy for bands and musicians to upload, edit and share recordings of their jam sessions and performances' },
  ],
});

</script>

<style>
.v-btn {
  text-transform: none !important;
}

.router-link {
  color: inherit;
}

.router-link:hover {
  color: #ea2776 !important;
}

.text-max-100 {
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-max-200 {
  max-width: 200px;
  width: fit-content;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-max-240 {
  max-width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-max-h-20 {
  height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.z-index-50 {
  z-index: 50;
}
</style>
