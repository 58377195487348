export function lowercase(value) {
    return value.toLowerCase()
}
  
export function uppercase(value) {
   return value.toUpperCase()
}

export function capitalize(value){
   return value.charAt(0).toUpperCase() + value.slice(1);
}

export function localeString(value){
   return value?.toLocaleString();
}

export function count(array = []){
   return array.length > 5 ? array.length : '';
}

export function count2(array = []){
   return array.length || 0;
}